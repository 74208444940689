// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timeline-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
}

.timeline-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.timeline-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border: 2px solid #ccc;
  border-radius: 50%;
  background-color: white;
}

.timeline-icon .circle {
  width: 12px;
  height: 12px;
  background-color: #ccc;
  border-radius: 50%;
}

.timeline-item.completed .timeline-icon {
  border-color: #278aff;
}

.timeline-item.completed .timeline-icon .circle {
  background-color: #278aff;
}

.timeline-label {
  margin-top: 8px;
  font-size: 12px;
  text-align: center;
}

.timeline-line {
  position: absolute;
  top: 12px;
  left: 22px;
  width: 240%;
  height: 2px;
  background-color: #278aff;
  z-index: -1;
}

.timeline-item.last-incomplete .timeline-line {
  background-color: #ccc;
}

.timeline-item.completed ~ .timeline-line {
  background-color: #278aff;
}
`, "",{"version":3,"sources":["webpack://./src/screens/Timeline.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,WAAW;EACX,aAAa;AACf;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;EAClB,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,UAAU;EACV,WAAW;EACX,WAAW;EACX,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".timeline-container {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n  padding: 20px;\n}\n\n.timeline-item {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  position: relative;\n}\n\n.timeline-icon {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 24px;\n  height: 24px;\n  border: 2px solid #ccc;\n  border-radius: 50%;\n  background-color: white;\n}\n\n.timeline-icon .circle {\n  width: 12px;\n  height: 12px;\n  background-color: #ccc;\n  border-radius: 50%;\n}\n\n.timeline-item.completed .timeline-icon {\n  border-color: #278aff;\n}\n\n.timeline-item.completed .timeline-icon .circle {\n  background-color: #278aff;\n}\n\n.timeline-label {\n  margin-top: 8px;\n  font-size: 12px;\n  text-align: center;\n}\n\n.timeline-line {\n  position: absolute;\n  top: 12px;\n  left: 22px;\n  width: 240%;\n  height: 2px;\n  background-color: #278aff;\n  z-index: -1;\n}\n\n.timeline-item.last-incomplete .timeline-line {\n  background-color: #ccc;\n}\n\n.timeline-item.completed ~ .timeline-line {\n  background-color: #278aff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
