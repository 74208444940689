// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown {
  position: relative;
}

.dropdown-toggle {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60vw;
  background-color: #f8f8f8;
  border-radius: 8px;
  padding: 17px 15px 17px 15px;
  margin: 10px;
}

.dropdown-content {
  display: none;
  overflow: hidden;
  padding: 17px 15px 17px 30px;
}

.dropdown-content.open {
  display: block;
  max-height: 500px;
  transition: max-height 0.3s ease-out;
}

.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  margin-left: 5px;
}

.arrow.up {
  transform: rotate(-135deg);
  transition: transform 0.3s ease-out;
}

.arrow.down {
  transform: rotate(45deg);
  transition: transform 0.3s ease-out;
}

.raise-btn {
  display: flex;
  border: 1px solid #2961b6;
  border-radius: 8px;
  width: 181px;
  height: 50px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 40px;
  cursor: pointer;
}

.raise-btn:hover {
  background-color: rgba(41, 97, 182, 0.3);
}
`, "",{"version":3,"sources":["webpack://./src/screens/CSS/Support.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,WAAW;EACX,yBAAyB;EACzB,kBAAkB;EAClB,4BAA4B;EAC5B,YAAY;AACd;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,4BAA4B;AAC9B;;AAEA;EACE,cAAc;EACd,iBAAiB;EACjB,oCAAoC;AACtC;;AAEA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,qBAAqB;EACrB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,0BAA0B;EAC1B,mCAAmC;AACrC;;AAEA;EACE,wBAAwB;EACxB,mCAAmC;AACrC;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,YAAY;EACZ,YAAY;EACZ,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,wCAAwC;AAC1C","sourcesContent":[".dropdown {\n  position: relative;\n}\n\n.dropdown-toggle {\n  cursor: pointer;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  width: 60vw;\n  background-color: #f8f8f8;\n  border-radius: 8px;\n  padding: 17px 15px 17px 15px;\n  margin: 10px;\n}\n\n.dropdown-content {\n  display: none;\n  overflow: hidden;\n  padding: 17px 15px 17px 30px;\n}\n\n.dropdown-content.open {\n  display: block;\n  max-height: 500px;\n  transition: max-height 0.3s ease-out;\n}\n\n.arrow {\n  border: solid black;\n  border-width: 0 3px 3px 0;\n  display: inline-block;\n  padding: 3px;\n  margin-left: 5px;\n}\n\n.arrow.up {\n  transform: rotate(-135deg);\n  transition: transform 0.3s ease-out;\n}\n\n.arrow.down {\n  transform: rotate(45deg);\n  transition: transform 0.3s ease-out;\n}\n\n.raise-btn {\n  display: flex;\n  border: 1px solid #2961b6;\n  border-radius: 8px;\n  width: 181px;\n  height: 50px;\n  justify-content: center;\n  align-items: center;\n  margin-top: 20px;\n  margin-bottom: 40px;\n  cursor: pointer;\n}\n\n.raise-btn:hover {\n  background-color: rgba(41, 97, 182, 0.3);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
