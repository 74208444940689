import React, { useRef, useState, useEffect } from "react";
import "./Onboarding.css";
import axios from "axios";
import { HOST_NAME } from "./const";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const Onboarding = () => {
  const inputRefs = useRef([]);
  const [email, setEmail] = useState(null);
  const [otp, setOTP] = useState(new Array(4).fill(""));
  const [step, setStep] = useState(1);
  const [error, setError] = useState(null);
  const [isOTPSent, setIsOTPSent] = useState(false);

  const navigate = useNavigate();

  const handleChange = (e, index) => {
    const newOtp = [...otp];
    newOtp[index] = e.target.value;
    setOTP(newOtp);
    const value = e.target.value;

    if (value.length === 1 && index < 3) {
      inputRefs.current[index + 1].focus();
    }

    if (value.length === 0 && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };
  //   const handleChange = (e, index) => {
  //     const newOtp = [...otp];
  //     newOtp[index] = e.target.value;
  //     setOTP(newOtp);
  //     const value = e.target.value;

  //     if (value.length === 1 && index < 3) {
  //       inputRefs.current[index + 1].focus();
  //     }

  //     // Optionally, handle backspace to focus previous input
  //     if (value.length === 0 && index > 0) {
  //       inputRefs.current[index - 1].focus();
  //     }
  //   };

  const sendOTP = async () => {
    await axios
      .post(`${HOST_NAME}/route/send-email-otp`, {
        email: email,
      })
      .then((res) => {
        setIsOTPSent(true);
      })
      .catch((e) => {
        setError("Enter valid email");
        console.log(e.message);
      });
  };

  const login = async () => {
    await axios
      .post(`${HOST_NAME}/route/verify-email-otp`, {
        email: email,
        otp: otp.join(""),
      })
      .then((res) => {
        navigate("/");
        Cookies.set("customer", email);
        console.log(res.data.token);
        Cookies.set("token", res.data.token);
        console.log("Logged in successfully");
        window.location.reload();
      })
      .catch((e) => {
        setError("Enter valid otp");
        console.log(e.message);
      });
  };

  return (
    <div className="onboarding-container">
      <div
        style={{
          position: "absolute",
          top: "0px",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          margin: "50px",
        }}
      >
        <img
          style={{
            width: "120px",
            height: "80px",
            position: "relative",
            left: "50px",
          }}
          src={require("../assets/left-rocket.png")}
        />
        <img
          style={{
            width: "150px",
            height: "80px",
            position: "relative",
            left: "-50px",
          }}
          src={require("../assets/logo.png")}
        />
      </div>

      <img
        style={{
          width: "200px",
          height: "200px",
          position: "absolute",
          left: "25%",
          top: "63%",
          transform: "rotate(-10deg)",
        }}
        src={require("../assets/stroke1.png")}
      />
      <img
        style={{
          width: "200px",
          height: "200px",
          position: "absolute",
          left: "58%",
          top: "30%",
          transform: "rotate(-10deg)",
        }}
        src={require("../assets/stroke2.png")}
      />
      <img
        style={{
          width: "80px",
          height: "80px",
          position: "absolute",
          left: "70%",
          top: "17%",
          transform: "rotate(-10deg)",
        }}
        src={require("../assets/rocket.png")}
      />

      <div className="login-container">
        <p style={{ color: "#2961B6", fontWeight: "650", fontSize: "30px" }}>
          Welcome Back !
        </p>
        <p>Login to continue</p>
        <p style={{ textAlign: "left", width: "70%" }}>Enter email</p>
        <input
          className="onboarding-input-text"
          type="text"
          placeholder="email@example.com"
          onChange={(e) => setEmail(e.target.value)}
        />
        {error !== null && (
          <p
            style={{
              width: "70%",
              textAlign: "left",
              fontSize: "11px",
              color: "red",
            }}
          >
            {error}
          </p>
        )}
        {isOTPSent && (
          <div style={{ width: "70%" }}>
            <p style={{ textAlign: "left", width: "70%" }}>Enter OTP</p>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              {[...Array(4)].map((_, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength="1"
                  ref={(el) => (inputRefs.current[index] = el)}
                  onChange={(e) => handleChange(e, index)}
                  style={{
                    width: "40px",
                    height: "40px",
                    textAlign: "center",
                    fontSize: "20px",
                    border: "1px solid rgba(0,0,0,0.3)",
                    borderRadius: "10px",
                  }}
                />
              ))}
            </div>
          </div>
        )}

        {/* <input
          className="onboarding-input-text"
          type="password"
          placeholder="Enter password"
        /> */}
        {isOTPSent ? (
          <div
            style={{
              width: "70%",
              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#2961B6",
              borderRadius: "10px",
              cursor: "pointer",
              marginTop: "20px",
            }}
            onClick={() => login()}
          >
            <p style={{ color: "#fff" }}>Login</p>
          </div>
        ) : (
          <div
            style={{
              width: "70%",
              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#2961B6",
              borderRadius: "10px",
              cursor: "pointer",
              marginTop: "20px",
            }}
            onClick={() => sendOTP()}
          >
            <p style={{ color: "#fff" }}>Generate OTP</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Onboarding;
