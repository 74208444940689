import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import Survey from "./screens/Survey";
import Customer from "./screens/Customer";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
// import LeverDetail from "./screens/LeverDetail";
// import Dashboard from "./screens/Dashboard";
import Aspire from "./screens/Aspire";
import Onboarding from "./screens/Onboarding";
import Mobile from "./screens/Mobile";

const App = () => {
  const [token, setToken] = useState();
  const [width, setWidth] = useState();
  useEffect(() => {
    const token = Cookies.get("token");
    setToken(token);

    const width = window.innerWidth;
    setWidth(width);
  }, [width]);
  if (width <= 900) {
    return <Mobile />;
  } else {
    if (token) {
      return (
        <Router>
          {/* <div> */}
          <Routes>
            {/* <Route path="/survey" element={<Survey />} /> */}
            {/* <Route path="/customer" element={<Customer />} />
          <Route path="/customer/:id" element={<LeverDetail />} /> */}

            <Route path="/login" element={<Customer />} />
            <Route path="/" element={<Customer />} />
          </Routes>
          {/* </div> */}
        </Router>
        // <div>
        //   <Customer />
        // </div>
      );
    } else {
      return (
        <Router>
          {/* <div> */}
          <Routes>
            {/* <Route path="/survey" element={<Survey />} /> */}
            {/* <Route path="/customer" element={<Customer />} />
          <Route path="/customer/:id" element={<LeverDetail />} /> */}

            <Route path="/login" element={<Onboarding />} />
            <Route path="/" element={<Onboarding />} />
          </Routes>
          {/* </div> */}
        </Router>
        // <div>
        //   <Customer />
        // </div>
      );
    }
  }
};

export default App;

// import React, { useState } from "react";
// import "./screens/Customer.css";
// // import { useNavigate } from "react-router-dom";
// import Dashboard from "./screens/Dashboard";
// import Insights from "./screens/Insights";
// import Profile from "./screens/Profile";
// import Support from "./screens/Support";
// import MainTab from "./screens/MainTab";

// const App = () => {
//   const [customerId, setCustomerId] = useState(null);
//   const [password, setPassword] = useState(null);
//   // const navigate = useNavigate();
//   const submit = () => {
//     // if (customerId !== null && password !== null) {
//     //   navigate("/url");
//     // }
//   };

//   const sidebarTabData = [
//     {
//       activeIcon: require("./assets/dashboard-active.png"),
//       inactiveIcon: require("./assets/dashboard-inactive.png"),
//       label: "Dashboard",
//     },
//     {
//       activeIcon: require("./assets/insights-active.png"),
//       inactiveIcon: require("./assets/insights-inactive.png"),
//       label: "Analytics",
//     },
//     {
//       activeIcon: require("./assets/profile-active.png"),
//       inactiveIcon: require("./assets/profile-inactive.png"),
//       label: "Profile",
//     },
//     {
//       activeIcon: require("./assets/support-active.png"),
//       inactiveIcon: require("./assets/support-inactive.png"),
//       label: "Support",
//     },
//   ];

//   const [activeTab, setActiveTab] = useState(1);

//   const handleTabClick = (index) => {
//     setActiveTab(index + 1);
//   };

//   const tabContents = [<Dashboard />, <Insights />, <Profile />, <Support />];

//   return (
//     <div className="container">
//       <div className="auth-container">
//         <p style={{ fontWeight: "700", fontSize: "25px" }}>Login</p>
//         <input
//           style={{ padding: "5px", borderRadius: "10px" }}
//           placeholder="Enter customer Id"
//           onKeyPress={(e) => e.key === "Enter" && submit()}
//           onChange={(e) => setCustomerId(e.target.value)}
//         />
//         <input
//           style={{ padding: "5px", borderRadius: "10px", margin: "20px" }}
//           placeholder="Enter password"
//           type="password"
//           onKeyPress={(e) => e.key === "Enter" && submit()}
//           onChange={(e) => setPassword(e.target.value)}
//         />
//         <div className="login-btn" onClick={() => submit()}>
//           <p>Submit</p>
//         </div>
//       </div>
//       <div className="sidebar">
//         <div
//           style={{
//             width: "100%",
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             marginTop: "20px",
//             flexDirection: "column",
//           }}
//         >
//           <img
//             src={require("./assets/logo.png")}
//             style={{ width: "150px", height: "60px" }}
//           />
//           <div
//             style={{
//               width: "80%",
//               height: "2px",
//               backgroundColor: "#CFCFCF",
//               marginTop: "15px",
//             }}
//           />
//         </div>
//       </div>
//       <div className="rightContainer">
//         <h1>P</h1>
//       </div>

//       <div className="sidebar">
//         <div
//           style={{
//             display: "flex",
//             flexDirection: "column",
//           }}
//         >
//           <div
//             style={{
//               display: "flex",
//               flexDirection: "row",
//               alignItems: "center",
//               height: "15%",
//               paddingLeft: "10%",
//               paddingTop: "5%",
//             }}
//           >
//             <img
//               alt=""
//               style={{ width: "150px", height: "50px" }}
//               src={require("./assets/logo.png")}
//             />
//           </div>
//           <div
//             style={{
//               width: "100%",
//               height: "2px",
//               backgroundColor: "#E2E4E9",
//               marginTop: "15px",
//             }}
//           />

//           <div
//             style={{
//               // position: "relative",
//               // left: "30px",
//               // marginLeft: "30px",
//               display: "flex",
//               justifyContent: "center",
//               height: "57vh",
//               marginTop: "20px",
//             }}
//           >
//             <div>
//               {sidebarTabData.map((tab, index) => (
//                 <MainTab
//                   key={index}
//                   activeIcon={tab.activeIcon}
//                   inactiveIcon={tab.inactiveIcon}
//                   label={tab.label}
//                   onClick={() => handleTabClick(index)}
//                   isActive={index === activeTab - 1}
//                 />
//               ))}
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="overview-container">
//         <div className="sidebartab-content">{tabContents[activeTab - 1]}</div>
//       </div>
//     </div>
//   );
// };

// export default App;
