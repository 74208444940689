import React from "react";

const Insights = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
  return (
    <div style={{ display: "flex", height: "100vh" }}>
      <div style={{ position: "relative", right: "-2%" }}>
        <iframe
          title="Insight Viewer"
          src="https://analytics.zoho.in/open-view/160757000005054416"
          style={{ width: "80vw", height: "100vh" }}
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default Insights;
