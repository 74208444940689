import React, { useState, useRef } from "react";
import "./Aspire.css";
// import axios from "axios";
import { CSVLink } from "react-csv";
// import CryptoJS from "crypto-js";

// const key = "mySecretKey123";

const Aspire = () => {
  const inputFile = useRef(null);
  // const [expandedUrl, setExpandedUrl] = useState("");

  const [userData, setUserData] = useState([]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    let fileExtension = "";

    if (file) {
      const fileName = file.name;
      fileExtension = fileName.split(".").pop().toLowerCase();
    }

    if (fileExtension !== "csv") {
      console.log("Not a valid CSV file");
      return;
    }

    reader.onload = (e) => {
      const csvData = e.target.result;
      const parsedData = parseCSV(csvData);
      //   setUserData(parsedData);
      convertURL(parsedData);
    };

    reader.readAsText(file);
  };

  const encryptText = (text) => {
    const substitutionMap = {
      a: "ers",
      b: "bnx",
      c: "iuo",
      d: "dyp",
      e: "knd",
      f: "che",
      g: "sow",
      h: "fwk",
      i: "ija",
      j: "kml",
      k: "gvq",
      l: "sed",
      m: "iuo",
      n: "jmn",
      o: "hjs",
      p: "wsl",
      q: "kja",
      r: "lad",
      s: "wlb",
      t: "fvh",
      u: "few",
      v: "vkh",
      w: "yel",
      x: "yus",
      y: "lkn",
      z: "sdi",
    };

    let encryptedText = "";
    for (let i = 0; i < text.length; i++) {
      const character = text[i].toLowerCase();
      if (substitutionMap.hasOwnProperty(character)) {
        encryptedText += substitutionMap[character];
      } else {
        encryptedText += character;
      }
    }
    return encryptedText;
  };

  const convertURL = (parsedData) => {
    const modifiedURL = parsedData.map((row, index) => {
      if (index === 0) return row;
      const modifiedRow = [...row];
      if (row[4]) {
        const parameter = row[4].split("/");
        const encryptedText = parameter[3];
        parameter[2] = "survey.thrivingworkplace.in";
        parameter[3] = encryptedText;
        modifiedRow[4] = parameter.join("/");
      }
      console.log(modifiedRow);
      return modifiedRow;
    });
    console.log(modifiedURL);
    setUserData(modifiedURL);
  };

  const parseCSV = (csvData) => {
    const rows = csvData.split("\n");
    const parsedData = rows.map((row) => row.split(","));
    return parsedData;
  };

  return (
    <div className="container">
      <div
        id="upload-sheet-btn"
        onClick={() => {
          if (inputFile.current) {
            inputFile.current.click();
          }
        }}
      >
        <input
          type="file"
          ref={inputFile}
          style={{ display: "none" }}
          onChange={handleFileUpload}
        />
        <p id="upload-sheet-btn-text">Upload sheet</p>
      </div>
      {userData.length !== 0 && (
        <CSVLink data={userData} filename={"survey_data.csv"}>
          Download CSV
        </CSVLink>
      )}

      {userData !== null && (
        <div>
          <table border="1">
            <thead>
              <tr>
                {userData.length > 0 &&
                  Object.keys(userData[0]).map((key) => (
                    <th key={key}>{key}</th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {userData.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {Object.values(row).map((value, columnIndex) => (
                    <td key={columnIndex}>{value}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Aspire;
