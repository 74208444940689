import React from "react";
import "../App.css";

const MainTab = ({ activeIcon, inactiveIcon, label, onClick, isActive }) => (
  <div
    className={`sidebar-item`}
    style={{
      backgroundColor: isActive ? "#0042A6" : "#fff",
      color: isActive ? "#fff" : "#000",
      borderRadius: isActive ? 8 : 0,
    }}
    onClick={onClick}
  >
    <img
      alt=""
      src={isActive ? activeIcon : inactiveIcon}
      style={{ width: "30px", height: "30px", marginLeft: "10px" }}
    />
    <p style={{ marginLeft: "10px" }}>{label}</p>
  </div>
);

export default MainTab;
