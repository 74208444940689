// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/Tabs.css */
.tabs {
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.tabs::-webkit-scrollbar {
  display: none;
}

.tab-button {
  background: none;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.tab-button.active {
  font-weight: 800;
}

.tab-button:hover {
  background-color: #f0f0f0;
}

.tab-content {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 30px;
}

.grid-view {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 30px;
}

.grid-item {
  border-radius: 8px;
  width: 60%;
  height: 30px;
  font-weight: 700;
}
`, "",{"version":3,"sources":["webpack://./src/screens/CSS/Initiatives.css"],"names":[],"mappings":"AAAA,4BAA4B;AAC5B;EACE,aAAa;EACb,6BAA6B;EAC7B,6BAA6B;EAC7B,mBAAmB;EACnB,wBAAwB;EACxB,qBAAqB;AACvB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,eAAe;EACf,iCAAiC;AACnC;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,0CAA0C;EAC1C,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,qCAAqC;EACrC,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":["/* src/components/Tabs.css */\n.tabs {\n  display: flex;\n  justify-content: space-around;\n  border-bottom: 1px solid #ccc;\n  margin-bottom: 20px;\n  -ms-overflow-style: none;\n  scrollbar-width: none;\n}\n\n.tabs::-webkit-scrollbar {\n  display: none;\n}\n\n.tab-button {\n  background: none;\n  border: none;\n  padding: 10px 20px;\n  cursor: pointer;\n  font-size: 16px;\n  transition: background-color 0.3s;\n}\n\n.tab-button.active {\n  font-weight: 800;\n}\n\n.tab-button:hover {\n  background-color: #f0f0f0;\n}\n\n.tab-content {\n  padding: 20px;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);\n  margin-bottom: 30px;\n}\n\n.grid-view {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  row-gap: 30px;\n}\n\n.grid-item {\n  border-radius: 8px;\n  width: 60%;\n  height: 30px;\n  font-weight: 700;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
