import React, { useState, useEffect } from "react";
import "./Customer.css";
// import { useNavigate } from "react-router-dom";
import Dashboard from "./Dashboard";
import Insights from "./Insights";
import Profile from "./Profile";
import Support from "./Support";
import MainTab from "./MainTab";
import Cookies from "js-cookie";
import { HOST_NAME } from "./const";
import axios from "axios";

// import { useNavigate } from "react-router-dom";
import SurveyProgress from "./SurveyProgress";
import Initiatives from "./Initiatives";

const Customer = () => {
  // const [customerId, setCustomerId] = useState(null);
  // const [password, setPassword] = useState(null);
  // const navigate = useNavigate();
  // const submit = () => {
  //   if (customerId !== null && password !== null) {
  //     navigate("/url");
  //   }
  // };

  // const navigate = useNavigate();

  const [logo, setLogo] = useState();

  const [showProgress, setShowProgress] = useState();

  useEffect(() => {
    // Check if user data is already stored in cookies
    // const customer = Cookies.get("customer");
    // if (!customer) {
    //   navigate("/login");
    // }

    const fetchData = async () => {
      await axios
        .get(`${HOST_NAME}/route/get-organization`, {
          method: "GET",
          headers: {
            token: Cookies.get("token"),
          },
        })
        .then((res) => {
          console.log(res.data.data);
          setLogo(res.data.data.logo);
        })
        .catch((err) => console.log(err));
      await axios
        .get(`${HOST_NAME}/route/get-customer`, {
          method: "GET",
          headers: {
            token: Cookies.get("token"),
          },
        })
        .then((res) => {
          console.log(res.data.data.showProgress);
          setShowProgress(res.data.data.showProgress);
        })
        .catch((err) => console.log(err));
    };
    fetchData();
  }, []);

  const sidebarTabData =
    showProgress === 1
      ? [
          {
            activeIcon: require("../assets/progress-active.png"),
            inactiveIcon: require("../assets/progress-inactive.png"),
            label: "Survey Progress",
          },
          {
            activeIcon: require("../assets/profile-active.png"),
            inactiveIcon: require("../assets/profile-inactive.png"),
            label: "Profile",
          },
          {
            activeIcon: require("../assets/support-active.png"),
            inactiveIcon: require("../assets/support-inactive.png"),
            label: "Support",
          },
        ]
      : [
          {
            activeIcon: require("../assets/dashboard-active.png"),
            inactiveIcon: require("../assets/dashboard-inactive.png"),
            label: "Dashboard",
          },
          {
            activeIcon: require("../assets/insights-active.png"),
            inactiveIcon: require("../assets/insights-inactive.png"),
            label: "Analytics",
          },
          {
            activeIcon: require("../assets/progress-active.png"),
            inactiveIcon: require("../assets/progress-inactive.png"),
            label: "Survey Progress",
          },
          {
            activeIcon: require("../assets/initiatives-active.png"),
            inactiveIcon: require("../assets/initiatives-inactive.png"),
            label: "Initiatives",
          },
          {
            activeIcon: require("../assets/profile-active.png"),
            inactiveIcon: require("../assets/profile-inactive.png"),
            label: "Profile",
          },
          {
            activeIcon: require("../assets/support-active.png"),
            inactiveIcon: require("../assets/support-inactive.png"),
            label: "Support",
          },
        ];

  const [activeTab, setActiveTab] = useState(showProgress === 1 ? 3 : 1);

  const handleTabClick = (index) => {
    setActiveTab(index + 1);
  };

  const tabContents =
    showProgress === 1
      ? [<SurveyProgress />, <Profile />, <Support />]
      : [
          <Dashboard />,
          <Insights />,
          <SurveyProgress />,
          <Initiatives />,
          <Profile />,
          <Support />,
        ];

  return (
    <div className="container">
      {/* <div className="auth-container">
        <p style={{ fontWeight: "700", fontSize: "25px" }}>Login</p>
        <input
          style={{ padding: "5px", borderRadius: "10px" }}
          placeholder="Enter customer Id"
          onKeyPress={(e) => e.key === "Enter" && submit()}
          onChange={(e) => setCustomerId(e.target.value)}
        />
        <input
          style={{ padding: "5px", borderRadius: "10px", margin: "20px" }}
          placeholder="Enter password"
          type="password"
          onKeyPress={(e) => e.key === "Enter" && submit()}
          onChange={(e) => setPassword(e.target.value)}
        />
        <div className="login-btn" onClick={() => submit()}>
          <p>Submit</p>
        </div>
      </div> */}
      {/* <div className="sidebar">
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
            flexDirection: "column",
          }}
        >
          <img
            src={require("../assets/logo.png")}
            style={{ width: "150px", height: "60px" }}
          />
          <div
            style={{
              width: "80%",
              height: "2px",
              backgroundColor: "#CFCFCF",
              marginTop: "15px",
            }}
          />
        </div>
      </div>
      <div className="rightContainer">
        <h1>P</h1>
      </div> */}

      <div className="sidebar">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              height: "15%",
              paddingLeft: "10%",
              paddingTop: "5%",
            }}
          >
            <img
              alt=""
              style={{ width: "160px", height: "70px" }}
              src={require("../assets/logo.png")}
            />
          </div>
          <div
            style={{
              width: "100%",
              height: "2px",
              backgroundColor: "#E2E4E9",
              marginTop: "15px",
            }}
          />

          <div
            style={{
              // position: "relative",
              // left: "30px",
              // marginLeft: "30px",
              display: "flex",
              justifyContent: "center",
              height: "57vh",
              marginTop: "20px",
            }}
          >
            <div>
              {sidebarTabData.map((tab, index) => (
                <MainTab
                  key={index}
                  activeIcon={tab.activeIcon}
                  inactiveIcon={tab.inactiveIcon}
                  label={tab.label}
                  onClick={() => handleTabClick(index)}
                  isActive={index === activeTab - 1}
                />
              ))}
            </div>
          </div>
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              height: "1px",
              width: "100%",
              backgroundColor: "rgba(0,0,0,0.2)",
              marginTop: "50px",
            }}
          />
          <img
            alt="become-logo"
            src={logo}
            style={{
              maxWidth: "80%",
              height: "auto",
              objectFit: "contain",
              marginLeft: "10px",
              position: "absolute",
              bottom: "20px",
            }}
          />
        </div>
      </div>
      <div className="overview-container">
        <div
          style={{
            // position: "relative",
            // left: "15%",
            // top: "10%",
            marginTop: "13%",
            marginLeft: "15%",
          }}
          className="sidebartab-content"
        >
          {tabContents[activeTab - 1]}
        </div>
      </div>
    </div>
  );
};

export default Customer;
