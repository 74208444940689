export const HOST_NAME = "https://api.thrivingworkplace.in";
// export const HOST_NAME = "http://localhost:4000";
export const BELOW_80 = "#fff06b";
// const le =
// {
//   levers: {
//     "Physiological Health": [
//       {
//         inf: "Employees have strong confidence in the leadership team.",
//       },
//       {
//         inf: "Become’s brand is highly regarded internally.",
//       },
//       {
//         inf: "Benefits are generally viewed positively.",
//       },
//       {
//         inf: "There is a need to enhance skills and professional development.",
//       },
//     ],
//     "Cultural Health": [
//       {
//         inf: "Employees highly regard the values upheld by Become.",
//       },
//       {
//         inf: "The practices within Become are seen as effective and unique.",
//       },
//       {
//         inf: "Ethical standards are perceived as strong and well-maintained.",
//       },
//       {
//         inf: "Workplaces and environments provided by Become are viewed positively.",
//       },
//     ],
//   },
// };
