// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.onboarding-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-image: linear-gradient(
    to bottom right,
    rgba(254, 254, 254, 1),
    rgba(189, 216, 255, 1)
  );
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 20px;
  width: 35%;
  height: 50%;
  flex-direction: column;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
  padding-top: 20px;
  padding-bottom: 50px;
  z-index: 1;
}

.onboarding-input-text {
  width: 70%;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}
`, "",{"version":3,"sources":["webpack://./src/screens/Onboarding.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb;;;;GAIC;AACH;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;EACtB,mBAAmB;EACnB,UAAU;EACV,WAAW;EACX,sBAAsB;EACtB,0CAA0C;EAC1C,iBAAiB;EACjB,oBAAoB;EACpB,UAAU;AACZ;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,kBAAkB;EAClB,oCAAoC;AACtC","sourcesContent":[".onboarding-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100vw;\n  height: 100vh;\n  background-image: linear-gradient(\n    to bottom right,\n    rgba(254, 254, 254, 1),\n    rgba(189, 216, 255, 1)\n  );\n}\n\n.login-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: #fff;\n  border-radius: 20px;\n  width: 35%;\n  height: 50%;\n  flex-direction: column;\n  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);\n  padding-top: 20px;\n  padding-bottom: 50px;\n  z-index: 1;\n}\n\n.onboarding-input-text {\n  width: 70%;\n  padding: 8px;\n  border-radius: 8px;\n  border: 1px solid rgba(0, 0, 0, 0.3);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
