import React, { useState, useRef } from "react";
import "./CSS/Initiatives.css";
import axios from "axios";
import { HOST_NAME } from "./const";
import Cookies from "js-cookie";

const Initiatives = () => {
  const [activeTab, setActiveTab] = useState(0);
  const tabsRef = useRef(null);
  const tabs = [
    "Physiological Health",
    "Spiritual Health",
    "Financial Health",
    "Career Health",
    "Business Health",
    "Digital & Tech Health",
  ];
  const content = [
    {
      lever: [
        {
          initiativename: "Become Healthy",
          levername: "Physiological Health",
          description:
            "Initiative proposes a multifaceted approach to support employees' physiological health through the integration of wellbeing apps, collective wellbeing practices, and wellbeing-linked rewards. The initiative aims to promote habit formation and overall health, reinforce physical wellbeing through community activities, and align rewards with health-promoting experiences and services. This holistic approach ensures that employees have the resources, support, and incentives needed to maintain and improve their physical and mental health.",
          outcome:
            "Employees would develop healthier habits, leading to improved physical and mental wellbeing, reduced stress levels, and enhanced overall quality of life.",
          duration: "Ongoing",
          cost: "₹₹₹₹₹",
          impact: "High",
          effort: "Medium",
        },
        {
          initiativename: "Become Fit",
          levername: "Physiological Health",
          description:
            "Wellbeing Initiative proposes enhancing employees' physical health by promoting regular exercise, raising awareness of physical wellbeing, and offering resources and programs dedicated to wellness. This initiative involves collaborating with professional physical trainers, offering diverse fitness activities, sharing informative materials, initiating workplace movement challenges, advocating for regular breaks, scheduling medical checkups, providing access to health screenings, organizing sports events, and establishing a company sports club.",
          outcome:
            "Encouraging regular physical activity boosts energy levels, enhances productivity, promotes healthy lifestyle choices, and contributes to a more active workforce. ",
          duration: "Ongoing",
          cost: "₹₹₹₹₹",
          impact: "High",
          effort: "High",
        },
      ],
    },
    {
      lever: [
        {
          initiativename: "Become Comes Together",
          levername: "Spiritual Health",
          description:
            "The 'Become Comes Together' initiative aims to strengthen employees' connection to the company’s mission by organizing community service events and social impact projects. This initiative will involve partnerships with local non-profits and social organizations to create ongoing volunteering opportunities. Additionally, a platform will be created for employees to propose and lead their own social impact projects, with resources and support provided by the company.",
          outcome:
            "Increased employee fulfillment, stronger alignment with the Become’s mission, enhanced community impact, and improved employee morale. Employees will feel more connected to the company’s mission and values, leading to higher engagement and satisfaction.",
          duration: "Ongoing",
          cost: "₹₹₹₹₹",
          impact: "High",
          effort: "Medium",
        },
        {
          initiativename: "Become Volunteering Time Off (VTO",
          levername: "Spiritual Health",
          description:
            "This initiative provides employees with paid time off to volunteer for social causes they are passionate about. Employees can choose from a list of approved non-profits or propose their own volunteering opportunities. The initiative includes a comprehensive policy outlining the program’s vision, objectives, and scope. Employees are required to submit a report detailing their contributions and experiences after their volunteering activities. The program aims to foster a sense of purpose, enhance community engagement, and support the company’s mission of societal impact.",
          outcome:
            "Employees will feel more connected to the Become’s mission, leading to increased fulfillment and morale. The initiative will promote a culture of giving back and community involvement, enhancing the company's reputation and employee satisfaction. Additionally, it will support personal growth and development through diverse volunteering experiences.",
          duration: "Ongoing",
          cost: "₹₹₹₹₹",
          impact: "High",
          effort: "Medium",
        },
      ],
    },
    {
      lever: [
        {
          initiativename: "Become Financially Secure",
          levername: "Financial Health",
          description:
            "Finance Drives will provide employees with access to better financial wellbeing through targeted support in four key areas: insurance, savings, investment, and financial management. This initiative will include monthly workshops conducted by financial experts who will provide practical advice and actionable steps. Employees will also receive resource materials such as brochures, guides, and online resources to support their financial decisions. Additionally, one-on-one consultations with financial advisors will be available quarterly to offer personalized guidance based on individual financial needs.",
          outcome:
            "Employees will have better access to information and tools to improve their financial health, leading to reduced financial stress, increased financial security, and enhanced overall wellbeing.",
          duration: "6 month",
          cost: "₹₹₹₹₹",
          impact: "High",
          effort: "Medium",
        },
        {
          initiativename: "Become Financially Literate",
          levername: "Financial Health",
          description:
            "Awareness and Specialty Sessions will focus on improving employees' financial awareness and management skills through a series of comprehensive education sessions. These sessions will cover essential topics such as budgeting, saving, investing, and emergency fund preparation. Monthly group workshops will encourage interactive learning and peer support, while quarterly one-on-one sessions will provide personalized financial advice tailored to individual employees' needs. The content will be customized based on the average salary slabs within the company to ensure relevance and applicability. Practical exercises and real-life examples will be included to enhance learning outcomes.",
          outcome:
            "Employees will gain better financial management skills, be better prepared for financial emergencies, and improve their savings practices. This will lead to improved financial health, reduced financial stress, increased employee satisfaction, and higher retention rates.",
          duration: "6 months",
          cost: "₹₹₹₹₹",
          impact: "High",
          effort: "Medium",
        },
      ],
    },

    {
      lever: [
        {
          initiativename: "Become Recognised",
          levername: "Career Health",
          description:
            "Rewards & Recognition Program will focus on rewarding and recognising employees' efforts, behaviours, values, contributions, personal and professional achievements, and performances. This initiative will include monthly awards, spot bonuses, and public recognition in company meetings and newsletters. Employees will be nominated by their peers and managers, and a committee will evaluate the nominations to ensure fairness and consistency. Additional perks such as extra paid leave days, gift vouchers, and professional development opportunities will also be included.",
          outcome:
            "Employees will feel valued and appreciated, leading to increased motivation, job satisfaction, and loyalty. This will foster a positive work environment and enhance overall performance and productivity.",
          duration: "Ongoing",
          cost: "₹₹₹₹₹",
          impact: "High",
          effort: "Medium",
        },
      ],
    },

    {
      lever: [
        {
          initiativename: "Become Gamified",
          levername: "Business Health",
          description:
            "Gamified Performance Programs proposes using gamification techniques to build a comprehensive performance program within the organisation. This initiative will involve designing interactive and engaging methods to track and enhance employee performance. Elements such as points, badges, leaderboards, and rewards will be incorporated to motivate employees and promote healthy competition. Regular feedback and performance reviews will be gamified to ensure continuous improvement and engagement.",
          outcome:
            "Employees will be more motivated and engaged, leading to improved performance, productivity, and job satisfaction.",
          duration: "6-12 months",
          cost: "₹₹₹₹₹",
          impact: "High",
          effort: "Medium",
        },
        {
          initiativename: "Become Streamlined",
          levername: "Business Health",
          description:
            "Systems and Processes Enhancement proposes conducting comprehensive reviews of existing systems and processes, implementing enhancements to streamline workflows, and increasing overall efficiency. Additional training for management to improve accessibility. Regular benchmarking of benefits will also be included to ensure competitiveness and employee satisfaction.",
          outcome:
            "The initiative will result in smoother workflows, increased efficiency, improved satisfaction, and enhanced overall business performance. Employees will benefit from clearer processes, and a better resource allocation",
          duration: "Ongoing monitoring and adjustments",
          cost: "₹₹₹₹₹",
          impact: "High",
          effort: "Medium",
        },
      ],
    },

    {
      lever: [
        {
          initiativename: "Become Advanced",
          levername: "Digital & Tech Health",
          description:
            "This initiative aims to enhance employees' physiological health by encouraging regular physical activity, increasing awareness of physical health, and providing resources and programs focused on well-being. The initiative will include fitness sessions with a physical trainer, health seminars, workplace movement challenges, medical checkups, and sports activities. Regular fitness sessions will be conducted by a professional trainer, covering various activities like yoga, strength training, and cardio exercises. Health seminars will raise awareness about the importance of physical health and provide tips on maintaining a healthy lifestyle. Workplace movement challenges and sports activities will encourage regular participation in physical activities.",
          outcome:
            "Improved physical health, higher energy levels, enhanced productivity, and better overall employee well-being. Employees will be more active and energetic, leading to a healthier, more productive workforce.",
          duration: "Ongoing",
          cost: "₹₹₹₹₹",
          impact: "High",
          effort: "High",
        },
      ],
    },
  ];

  const scrollTabs = (direction) => {
    const scrollAmount = 200; // Adjust scroll amount as needed
    if (tabsRef.current) {
      tabsRef.current.scrollBy({
        left: direction === "left" ? -scrollAmount : scrollAmount,
        behavior: "smooth",
      });
    }
  };

  return (
    <div
      style={{
        position: "absolute",
        top: "50px",
        left: "18%",
        width: "65vw",
      }}
    >
      <div
        style={{ width: "60vw", height: "1px", backgroundColor: "#CFCFCF" }}
      />
      <p style={{ fontWeight: "510", fontSize: "20px" }}>Initiatives</p>
      <div
        style={{ width: "60vw", height: "1px", backgroundColor: "#CFCFCF" }}
      />
      <div>
        {/* <button
          onClick={() => scrollTabs("left")}
          style={{
            backgroundColor: "#0042A6",
            color: "#fff",
            border: "none",
            borderRadius: "4px",
            padding: "10px",
            cursor: "pointer",
          }}
        >
          &#8592; {/* Left arrow 
        </button> */}
        <div
          className="tabs"
          style={{
            width: "70vw",
          }}
          ref={tabsRef}
        >
          {tabs.map((tab, index) => (
            <div
              key={index}
              className={`tab-button ${activeTab === index ? "active" : ""}`}
              onClick={() => setActiveTab(index)}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#0042A6",
                borderRadius: "8px",
                width: "200px",
                height: "20px",
                margin: "10px",
                color: `${activeTab === index ? "#fff06b" : "#fff"}`,
                whiteSpace: "nowrap",
              }}
            >
              <p style={{ width: "100%", fontSize: "14px" }}>{tab}</p>
            </div>
          ))}
        </div>
        {/* <button
          onClick={() => scrollTabs("right")}
          style={{
            backgroundColor: "#0042A6",
            color: "#fff",
            border: "none",
            borderRadius: "4px",
            padding: "10px",
            cursor: "pointer",
          }}
        >
          &#8594;
        </button> */}
        <div style={{ width: "62vw" }} className="tab-content">
          {content[activeTab].lever.map((lever, leverIndex) => (
            <div>
              {/* <div
                style={{
                    backgroundColor: "#fff06b",
                  borderRadius: "8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                  top: "-20px",
                }}
              > */}
              <p
                style={{
                  fontWeight: "800",
                  fontSize: "25px",
                  color: "#0042A6",
                }}
              >
                {lever.initiativename}
              </p>
              {/* </div> */}
              <p style={{ fontWeight: "700" }}>What we can do?</p>
              <p>{lever.description}</p>
              <p style={{ fontWeight: "700" }}>
                What can you expect as an outcome?
              </p>
              <p>{lever.outcome}</p>

              <div className="grid-view">
                <div className="grid-item">Duration: {lever.duration}</div>
                <div className="grid-item">Cost: {lever.cost}</div>
                <div className="grid-item">Impact: {lever.impact}</div>
                <div className="grid-item">Effort: {lever.effort}</div>
              </div>
              {content[activeTab].lever.length !== 0 &&
                leverIndex !== content[activeTab].lever.length - 1 && (
                  <div
                    style={{
                      height: "50px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: "1px",
                        backgroundColor: "rgba(0,0,0,0.7)",
                      }}
                    />
                  </div>
                )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
//{content[activeTab]}

export default Initiatives;
