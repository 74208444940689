// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input[type="range"] {
  -webkit-appearance: none;
  margin: 18px 0;
  width: 10%;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 30%;
  height: 8.4px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: #000;
  border-radius: 30px;
  border: 0.2px solid #010101;
}
input[type="range"]::-webkit-slider-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 10px;
  width: 8px;
  border-radius: 20px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,cAAc;EACd,UAAU;AACZ;AACA;EACE,aAAa;AACf;AACA;EACE,UAAU;EACV,aAAa;EACb,eAAe;EACf,oDAAoD;EACpD,gBAAgB;EAChB,mBAAmB;EACnB,2BAA2B;AAC7B;AACA;EACE,oDAAoD;EACpD,yBAAyB;EACzB,YAAY;EACZ,UAAU;EACV,mBAAmB;EACnB,mBAAmB;EACnB,eAAe;EACf,wBAAwB;AAC1B","sourcesContent":["input[type=\"range\"] {\n  -webkit-appearance: none;\n  margin: 18px 0;\n  width: 10%;\n}\ninput[type=\"range\"]:focus {\n  outline: none;\n}\ninput[type=\"range\"]::-webkit-slider-runnable-track {\n  width: 30%;\n  height: 8.4px;\n  cursor: pointer;\n  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;\n  background: #000;\n  border-radius: 30px;\n  border: 0.2px solid #010101;\n}\ninput[type=\"range\"]::-webkit-slider-thumb {\n  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;\n  border: 1px solid #000000;\n  height: 10px;\n  width: 8px;\n  border-radius: 20px;\n  background: #ffffff;\n  cursor: pointer;\n  -webkit-appearance: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
